<template>
    <div class="config">
        <div class="flex flex-child-center flex-justify-content-between">
            利润设置
<!--            <el-switch-->
<!--                v-model="value"-->
<!--                active-color="#4B83F0"-->
<!--                inactive-color="#E4E4E4"-->
<!--                >-->
<!--            </el-switch>-->
        </div>
        <div class="content-box">
           <div style="height: 90%;overflow: hidden;overflow-y: scroll;">
               <div class="listbox">
                   <div style="width: 60%" class="listbox_line padding-40 no-padding-bottom no-padding-right no-padding-top">产品类型</div>
                   <div style="width: 20%" class="listbox_line text-center">固定值</div>
                   <div style="width: 20%" class="listbox_line no-border-right text-center">百分比</div>
               </div>
               <div v-for="(item,index) in list" :key="index">
                   <div class="listbox">
                       <div style="width: 60%" class="listbox_line padding-40 no-padding-bottom no-padding-right no-padding-top color_line">{{item.type_name}}</div>
                       <div style="width: 20%" class="listbox_line text-center color_line">
                           <el-input v-model="item.fix_profit" placeholder="请输入" @input="fixProfit($event,index)" type="number"></el-input>
                       </div>
                       <div style="width: 20%" class="listbox_line no-border-right text-center color_line flex flex-child-center flex-justify-content-center">
                           <el-input v-model="item.percent_profit" placeholder="请输入" @input="percentProfit($event,index)"  type="number"></el-input>
                           <div class="margin-20 no-margin-right no-margin-top no-margin-bottom">%</div>
                       </div>
                   </div>
                   <div v-for="(item1,index1) in item.goods_list" :key="index1"  class="listbox">
                       <div style="width: 60%" class="listbox_line padding-80 no-padding-bottom no-padding-right no-padding-top color_line_child">{{item1.goods_name}}</div>
                       <div style="width: 20%" class="listbox_line text-center color_line_child">
                           <el-input v-model="item1.fix_profit" placeholder="请输入"  type="number"></el-input>
                       </div>
                       <div style="width: 20%" class="listbox_line no-border-right text-center color_line_child flex flex-child-center flex-justify-content-center">
                           <el-input v-model="item1.percent_profit" placeholder="请输入"  type="number"></el-input>
                           <div class="margin-20 no-margin-right no-margin-top no-margin-bottom">%</div>
                       </div>
                   </div>
               </div>
           </div>
            <el-button type="primary" round @click="buttonSubmit()">保存</el-button>
        </div>

    </div>
</template>

<script>
import store from "../store";

export default {
    name: "profit",
    data() {
        return {
            // value:false,
            list:[],
            ketword:'',
        }
    },
    mounted() {
        this.onList();
    },
    // created() {
    //     this.$store.watch((state,getters) => {
    //         return state.keyword
    //     },(res) =>{
    //         this.keyword = this.$store.state.keyword
    //         this.onList()
    //     })
    // },
    methods: {
        onList() {
            this.$axios.post('api/kuerp/order/index/ProfitList', {
                // ketword:this.keyword,
            }).then(res => {
                this.list = res.data.data
            })
        },
        /**
         * 固定值
         * */
        fixProfit(eve,i) {
            this.list.map((item,index) => {
                if(index == i) {
                    item.goods_list.map((item1,index1) => {
                        item1.fix_profit = eve
                    })
                }
            })
        },
        /**
         * 百分比
         * */
        percentProfit(eve,i) {
            this.list.map((item,index) => {
                if(index == i) {
                    item.goods_list.map((item1,index1) => {
                        item1.percent_profit = eve
                    })
                }
            })
        },
        /**
         * 保存
         * */
        buttonSubmit() {
            this.$axios.post('api/kuerp/order/index/SaveProfit', {
                list:this.list,
            }).then(res => {
                if(res.data.code == 200) {
                    this.onList();
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep {
    .el-input {
        width: 50%;
    }
    .el-input__inner {
        border: 0.01rem solid #F0F0F0;
    }
    .el-button.is-round {
        width: 1.62rem;
        height: 0.52rem;
        background: #4B83F0;
        border-radius: 0.04rem!important;
        margin-top: 0.1rem;
        float: right;
    }
}
.config {
    height: 100%;
    padding: 0.24rem 0.34rem;
    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }
    .content-box {
        height: calc(100% - 5%);
        //overflow: hidden;
        //overflow-y: scroll;
        .listbox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #F7F7F7;
            .listbox_line {
                height: 0.5rem;
                line-height: 0.5rem;
                border-right: 0.02rem solid #F0F0F0;
                border-bottom: 0.01rem solid #E4E4E4;
                font-size: 0.12rem;
                font-weight: 500;
                color: #2F2F2F;
            }
            .color_line {
                background: #DBE6FC;
            }
            .color_line_child {
                background: #FFFFFF;
            }
        }
    }
}
</style>
